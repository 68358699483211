import { container, primaryColor } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0 0 10px 0",
    fontWeight: "500",
    fontSize: "16px",
    textTransform: "capitalize",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block",
    color:"#e88334"
  },
  right: {
    margin: "0",
    float: "right!important",
    color:"#fff"
  },
  footer: {
    background:"#3d65ab",
    paddingTop: "20px",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: "#e88334",
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "block",
    padding: "0px",
    width: "auto",
    color:"#fff",
    textAlign:"center",
    fontSize:"14px",
    fontWeight:"500"
  },
  footer :{
    "& a":{
      color:"#fff",
      marginRight:"20px",
      fontSize:"14px"
    }
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  }
};
export default footerStyle;
