/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/aboutStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
export default function AboutSection() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer justify="center">

            
            <GridItem xs={12} sm={6} md={6}>
              <iframe width="100%" height="300" src="https://www.youtube.com/embed/C4jjFanHZo8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </GridItem>



            <GridItem xs={12} sm={6} md={6}>
            <h2 style={{color:"#ffffff",fontSize:"28px",fontWeight:"800",width:"100%",float:"left",textAlign:"left", marginBottom:"30px", marginLeft:"20px",marginTop:"0px"}}>How TruckSewa Works ?</h2>
            <ul className={classes.workList}>
              <li>Aliquam a augue suscipit, luctus neque purus ipsum neque dolor</li>
              <li>Aliquam a augue suscipit, luctus neque purus ipsum neque dolor</li>
              <li>Aliquam a augue suscipit, luctus neque purus ipsum neque dolor</li>
              <li>Aliquam a augue suscipit, luctus neque purus ipsum neque dolor</li>
              <li>Aliquam a augue suscipit, luctus neque purus ipsum neque dolor</li>
              <li>Aliquam a augue suscipit, luctus neque purus ipsum neque dolor</li>
            </ul>
            </GridItem>
          </GridContainer>

        </div>
    </div>
    
  );
}
