import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import bgAboutImage from '../assets/img/map.jpg'

const bgImageAbout = {
  img: {
    backgroundImage: `url(${bgAboutImage})`
  }
}


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div style={{background:"#fff"}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      


      <div className={classNames(classes.breadCrumb)}>
                <h1 style={{textAlign:isRTL === 'rtl'?'right':'center',fontSize:"40px",
                fontWeight:"800",marginTop:"100px",paddingBottom:"20px"}}>About<span style={{color:"#e88334"}}>&nbsp; Us</span></h1>
                
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}>
 
        <div className={classes.container} style={{}}>
               <GridContainer>
          
         <GridItem xs={12} sm={12} md={12}>
                <p style={{color:"#141415", fontWeight:"400",fontSize:"18px", textAlign:"justify", lineHeight:"30px"}}>Trucksewa is amongst the most renowned and appreciated truck 
                service providers in the country. We started our journey with the aim of delivering the logistics and transportation excellence to our 
                customers ensuring their comfort as well as success.</p>
              
              <p style={{color:"#141415", fontWeight:"400",fontSize:"18px", textAlign:"justify", lineHeight:"30px"}}>At Trucksewa, we render a broad spectrum of services incorporating 
              Roadways Transportation Services, Logistics Services, Supply Chain Consulting, Warehouse Management, Inventory Control,
              Bulk Parcel Delivery etc. We provide innovative and dynamic transport solution with the right people, modern techniques, and 
              advanced technologies to drive unmatched value for your business. Today, our company has firmly established itself as the 'Market Leader' in the logistics and supply chain industry in Nepal. 
              Drawing inspiration from our work and the standard we set, we have been proving ourselves every day in best quality, 
              cost effectiveness, client satisfaction and assurance to deadlines.</p>
            
           </GridItem>
         

       

        </GridContainer>

            </div>
        </div>



    <div className={classes.section} style={bgImageAbout.img}>
      <div className={classes.container} style={{padding:"40px"}}>
        <GridContainer justify="center">

          
         <GridItem xs={12} sm={12} md={3}>

        
          <div className={classes.iconContent}>
                  <h3 style={{color:"#fa4318",fontSize:"36px",fontWeight:"700",width:"100%",float:"left",textAlign:"center"}}>1656</h3>
                  <p style={{color:"#141415",fontSize:"18px",fontWeight:"500",width:"100%",float:"left",textAlign:"center"}}>Completed Delivery</p>
                  </div>

          </GridItem>    
          
         <GridItem xs={12} sm={12} md={3}>

        
          <div className={classes.iconContent}>
                  <h3 style={{color:"#fa4318",fontSize:"36px",fontWeight:"700",width:"100%",float:"left",textAlign:"center"}}>1656</h3>
                  <p style={{color:"#141415",fontSize:"18px",fontWeight:"500",width:"100%",float:"left",textAlign:"center"}}>Completed Delivery</p>
                  </div>

          </GridItem>  
          
         <GridItem xs={12} sm={12} md={3}>

        
          <div className={classes.iconContent}>
                  <h3 style={{color:"#fa4318",fontSize:"36px",fontWeight:"700",width:"100%",float:"left",textAlign:"center"}}>1656</h3>
                  <p style={{color:"#141415",fontSize:"18px",fontWeight:"500",width:"100%",float:"left",textAlign:"center"}}>Completed Delivery</p>
                  </div>

          </GridItem>  
          
         <GridItem xs={12} sm={12} md={3}>

        
          <div className={classes.iconContent}>
                  <h3 style={{color:"#fa4318",fontSize:"36px",fontWeight:"700",width:"100%",float:"left",textAlign:"center"}}>1656</h3>
                  <p style={{color:"#141415",fontSize:"18px",fontWeight:"500",width:"100%",float:"left",textAlign:"center"}}>Completed Delivery</p>
                  </div>

          </GridItem>  


        </GridContainer>

      </div>
    </div>




      <Footer />
    </div>
  );
}
