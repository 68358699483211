/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { SpeakerNotesOff } from "@material-ui/icons";

const useStyles = makeStyles(styles);


export default function Footer(props) {
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  let history = useHistory();
  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();

  return (
   <div className="footercard">
   <img src={require("../../assets/img/curve.png").default} alt="Truck Sewa" style={{width:"100%", background:"#FFFFFF", paddingTop:"50px"}}/>
    <footer className={footerClasses} style={{ backgroundColor:"#006ABC"}}>
      <div className={classes.container} style={{direction:isRTL === 'rtl' ? 'rtl' : 'ltr'}}>


        <GridContainer justify="left">
          <GridItem className={classes.Footerlist} xs={12} sm={12} md={12} style={{borderBottom:"solid thin #345b9fc7", paddingBottom:"20px",marginBottom:"20px",color:"#fff",overflow:"hidden"}}>
            <a href={""}>Kathmandu</a>
            <a href={""}>Pokhara</a>
            <a href={""}>Chitwan</a>
            <a href={""}>Biratnagar</a>
            <a href={""}>Lumbini</a>
            <a href={""}>Nepalgunj</a>            
            <a href={""}>Kathmandu</a>
            <a href={""}>Pokhara</a>
            <a href={""}>Chitwan</a>
            <a href={""}>Biratnagar</a>
            <a href={""}>Lumbini</a>
            <a href={""}>Nepalgunj</a>
            <a href={""}>Chitwan</a>
            <a href={""}>Biratnagar</a>
            <a href={""}>Lumbini</a>
          </GridItem>
          
         <GridItem xs={12} sm={12} md={12} style={{textAlign:"center"}}>
          <List className={classes.list}>
            
            <ListItem className={classes.inlineBlock}>
              Anamnagar, Kathmandu
            </ListItem>
            <ListItem className={classes.inlineBlock}>
             +977-9851041937
            </ListItem>
            <ListItem className={classes.inlineBlock}>
             Email : trucksewanepal.com
            </ListItem>
          </List>

          <h4 style={{fontSize:"14px",fontWeight:"500",color:"#fff", textDecoration:"underline",textAlign:"center"}}>Open Hours :</h4>
          <p style={{color:"#ffefef",fontSize:"14px",fontWeight:"500",textAlign:"center"}}>Mon - Sat: 8 am - 5 pm, Sunday: CLOSED</p>

          </GridItem>

          {settings && settings.CompanyWebsite?
        <div className={isRTL === 'ltr' ? classes.left : classes.right}  style={{padding: "15px 0",fontWeight:"800", color:"#FFFFFF", width:"100%"  }}>
         
        <div className="footertext" style={{textAlign:"center"}}>
          <a onClick={(e) => { e.preventDefault(); history.push('/privacy-policy') }} style={{cursor:"pointer", color:"#FFFFFF", fontSize:"12px",  fontWeight: "400", margin:"0 10px"}}>Privacy policy</a>
          <a href={""} style={{color:"#FFFFFF", fontSize:"12px",  fontWeight: "400", margin:"0 10px"}}>FAQ</a>
          <a href={""} style={{color:"#FFFFFF",  fontSize:"12px", fontWeight: "400", margin:"0 10px"}}>Partner With Us</a>
        </div> 
        <div classNames="footerbottom" >
         <span style={{textAlign:"center",fontSize:"10px",fontWeight:"300",marginLeft:"65px"}}>
         Design & Developed By : 
          
          <a
            href={settings.CompanyWebsite}
            className={aClasses}
            target="_blank"
          style={{color:"#FFFFFF"}}>
            
            {settings.CompanyName}
            </a>
            &copy; {1900 + new Date().getYear() + " "} 
            </span>

            <img xs={12} src={require("assets/img/androidstores.png").default} alt="Truck Sewa" style={{float:"right",width:"81px", height:"26px", margin:"0 75px"}}/>
            <img xs={12} src={require("assets/img/googleplay.png").default} alt="Truck Sewa" style={{marginTop:"5px",float:"right",width:"81px", height:"26px", margin:"0 10px"}}/>
          
          
          </div>
        </div>
        :null}

        </GridContainer>


       
      </div>
    </footer>
    </div>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
