export const FirebaseConfig = {
	"projectId": "trucksewa-v2",
	"appId": "1:240337549376:web:33374d25d8df0dcfe847a5",
	"databaseURL": "https://trucksewa-v2-default-rtdb.firebaseio.com",
	"storageBucket": "trucksewa-v2.appspot.com",
	"locationId": "asia-south1",
	"apiKey": "AIzaSyA3Wfu9wuZUJms-83AQzvvmfOD9UqjmynE",
	"authDomain": "trucksewa-v2.firebaseapp.com",
	"messagingSenderId": "240337549376",
	"measurementId": "G-EZKH9QH3W1"
};