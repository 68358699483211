import { BorderTop } from "@material-ui/icons";

const cardBodyStyle = {
  cardBody: {
    padding: "0.9375rem 1.875rem",
    flex: "1 1 auto",
    BorderTop:"solid thin #ddd !important"
  }
};

export default cardBodyStyle;
