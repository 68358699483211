/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/serviceStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function ServiceSection() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
        

    </div>
  );
}
