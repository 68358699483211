/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/aboutStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
export default function AboutSection() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section} style={{padding:"0"}}> 
        <div className={classes.container}>
          <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <img src={require("../../assets/img/s-truck-1.png").default} alt="Truck Sewa"/>
            </GridItem>
            
            <GridItem xs={12} sm={12} md={6}>
            <h2>Why Choose Truck Sewa?</h2>
             <ul>
                        <li>Your truck's are always booked.</li>
                        <li>Live Tracking your truck anytime, anywhere.</li>
                        <li>Don't waste your time on loading.</li>
                        <li>Anywhere, Anytime find a truck.</li>
                        <li>Fair Price and Your Suitable Time </li>
                        <li>Find Trucks Near around you.</li>
                      </ul>
            </GridItem>
          </GridContainer>

        </div>
    </div>
    
  );
}
