import { container } from "assets/jss/material-kit-react.js";
import { title } from "assets/jss/material-kit-react.js";

const serviceStyle = {
  section: {
    padding: "70px 0",
  },
  container,
  textCenter: {
    textAlign: "center"
  },
  abtWrap: {
    background:"#fff",
    margin:"30px 0px"
  },
  socials: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    fontSize: "20px",
    marginRight: "4px"
  },
  title: {
    ...title,
    marginBottom: "2rem",
    marginTop: "10px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999",
    marginBottom: "2rem",
    marginTop: "2rem",
  },
  triobanner: {
    width:'60%',
  },
  facilityContent: {
    bottom: "-25px",
    position: "absolute"
  },
  downloadlinks:{
    width: 10
  },
  test: {
    background:"#000"
  },
  roundImg: {
    float: "left",
    width: "95px",
    height: "95px",
    overflow: "hidden",
    background:"#000",
    margin: "10px 20px 10px 10px"
  },
 
  roundWrap: {
    "& h3":{
      fontSize: "21px",
      fontWeight: "bold",
      margin: "10px",
      color: "#333"
    }
  },

  
  
};

export default serviceStyle;
