/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/serviceStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(styles);

export default function ServiceSection() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        
        <GridContainer className="tracking">
  
         <GridItem className="trackimg" xs={12} sm={6} md={6} style={{ position:"relative"}} >
              <img src={require("../../assets/img/mobile.png").default} alt="Truck Sewa" style={{width:"256px", position: "absolute", top:"-90px",marginBottom:"15px"}}/>

          </GridItem>    
          
         <GridItem className="textbox" xs={12} sm={6} md={6}>
          <h2 style={{color:"#FFFFFF",fontSize:"52px",fontWeight:"800",paddingTop:"12px", lineHeight:"62px",width:"100%",float:"left",textAlign:"left"}}> Track your vehicles or driver </h2>
          <span style={{color:"#FFFFFF", fontSize:"20px",fontWeight:"300",width:"100%",lineHeight:"35px", float:"left",textAlign:"left"}}>Feugiat eros, ac tincidunt ligula massa in faucibus orci luctus posuere cubilia curae integer congue leo metus</span>

          <img style={{ width:"150px", margin:"20px 10px 10px 10px"}} src={require("../../assets/img/googleplay.png").default} alt="Truck Sewa"/>
          <img style={{ width:"150px", margin:"20px 10px 10px 10px"}} src={require("../../assets/img/androidstores.png").default} alt="Truck Sewa"/>

          </GridItem>  


        </GridContainer>

      </div>
    </div>
  );
}
