/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/serviceStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

export default function ServiceSection() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section} style={{padding:"0"}}> 
      <div className={classes.container}>
        <div className="appbenefit">
      <h2 style={{color:"#343f52",fontSize:"34px",fontWeight:"800",paddingTop:"50px",width:"100%",float:"left",textAlign:"center", marginBottom:"80px"}}>Benefits of <span style={{color:"#e98237"}}>TruckSewa</span></h2>
          
        <GridContainer justify="center">
          
         <GridItem xs={12} sm={12} md={4}>

            <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px"}}>
          <img src={require("../../assets/img/load-truck.png").default} alt="Truck Sewa"/>
          </div>

          <div className="roundContent">
            <h3 style={{fontSize:"20px", fontWeight:"bold",margin:"10px",color:"#343f52"}}>Full Load Services</h3>
            <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px 0px",lineHeight:"23px"}}>We provide Full Truck load transportation services with varied type of trucks available with the click of a button.</p>
          </div>

            <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px"}}>
          <img src={require("../../assets/img/price.png").default} alt="Truck Sewa"/>
          </div>

          <div className="roundContent">
            <h3 style={{fontSize:"20x", fontWeight:"bold",margin:"10px",color:"#343f52"}}>Fair price</h3>
            <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px",lineHeight:"23px"}}>With our fare calculator, we instantaneously give you best possible rates online.</p>
          </div>



            <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px"}}>
          <img src={require("../../assets/img/live-tracking.png").default} alt="Truck Sewa"/>
          </div>

          <div className="roundContent">
            <h3 style={{fontSize:"20px", fontWeight:"bold",margin:"10px",color:"#343f52"}}>Live Tracking</h3>
            <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px",lineHeight:"23px"}}>Get real time updates of your goods with our simplified technology.</p>
          </div>



            <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px",lineHeight:"23px"}}>
          <img src={require("../../assets/img/book.png").default} alt="Truck Sewa"/>
          </div>

          <div className="roundContent">
            <h3 style={{fontSize:"20px", fontWeight:"bold",margin:"10px",color:"#343f52"}}>Truck is Always Booked</h3>
            <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px",lineHeight:"23px"}}>We make sure your truck is always booked.</p>
          </div>



          </GridItem>
          
          <GridItem xs={12} sm={12} md={4}>
          <img src={require("../../assets/img/mobile.png").default} alt="Truck Sewa" style={{width:"100%",marginBottom:"20px"}}/>
           </GridItem>
           
          
         <GridItem xs={12} sm={12} md={4}>

         <div className="roundWrapper"> 
            <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px"}}>
          <img src={require("../../assets/img/nearby.png").default} alt="Truck Sewa"/>
          </div>

          <div className="roundContent">
            <h3 style={{fontSize:"20px", fontWeight:"bold",margin:"10px",color:"#343f52"}}>Truck's Nearby</h3>
            <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px",lineHeight:"23px"}}>Trucks availability is guaranteed nearby anytime ready to load and go.</p>
          </div>
          </div>

          <div className="roundWrapper"> 
            <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px"}}>
          <img src={require("../../assets/img/quick.png").default} alt="Truck Sewa"/>
          </div>

          <div className="roundContent">
            <h3 style={{fontSize:"20px", fontWeight:"bold",margin:"10px",color:"#343f52"}}>Quick & Easy to use</h3>
            <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px",lineHeight:"23px"}}>Trucksewa is easy to use and book vehicles as per your requirement.</p>
          </div>
          </div>

          <div className="roundWrapper"> 
            <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px"}}>
            <img src={require("../../assets/img/secure.png").default} alt="Truck Sewa"/>
            </div>

            <div className="roundContent">
              <h3 style={{fontSize:"20px", fontWeight:"bold",margin:"10px",color:"#343f52"}}>Safety Guaranteed</h3>
              <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px",lineHeight:"23px"}}>We make sure your goods are loaded and delivered safely.</p>
            </div>
          </div>

<div className="roundWrapper" style={{float:"left",marginTop:"10px"}}> 
  <div className="roundImg" style={{float:"left", width:"100px", height:"100px",overflow:"hidden",margin:"10px 20px 10px 10px"}}>
  <img src={require("../../assets/img/load-truck.png").default} alt="Truck Sewa"/>
  </div>

  <div className="roundContent">
  <h3 style={{fontSize:"20px", fontWeight:"bold",margin:"0px",color:"#343f52"}}>Cargo Insurance</h3>
  <p style={{fontFamily:"inherit",color:"#60697b",fontSize:"14px",fontWeight:"400",letterSpacing:".5px",margin:"0px 0px 35px",lineHeight:"23px"}}>We make sure your goods are loaded and delivered safely.</p>
  </div>

  

</div>



          </GridItem>

        </GridContainer>
        </div>
      </div>
    </div>
  );
}
