/*eslint-disable*/
/*import React from "react";*/
import React,{useState} from 'react';
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/serviceStyle.js";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(styles);

export default function ServiceSection() {
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const { t } = useTranslation();

  const [state,setState]=useState(false);
  let url="";
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        
        <GridContainer justify="center">
            
       
            <GridItem xs={12} sm={3} md={3}>
            <div className="appBox" style={{padding:"20px",background:" #FFFFFF",boxShadow: "5px 10px 30px -10px rgba(0, 0, 0, 0.2)",borderRadius: "25px"}}>
                <img style={{marginLeft:"4.8rem"}} src={require("../../assets/img/load-unload.png").default} alt="Truck Sewa"/>
                <h3 style={{textAlign:"center",fontSize:"18px",fontWeight:"700", color:"#343F52",lineHeight:"21px"}}>Package Load Unload</h3>
                <p style={{marginBottom:"20px",textAlign:"center",fontSize:"12px",fontWeight:"300", color:"#60697B",lineHeight:"16.9px"}}>Consec tetur adipiscing elit ipsum dolor sit amet, consec tetur amet</p>
                  <div style={{width:"100%", textAlign:"center"}}>
                <a href={url} style={{padding: "8px 25px", borderRadius:" 20px", border: "solid thin #E98237",
                    fontSize: "14px",fontWeight: "500",color: "#E98237"}}>Read More</a>
                </div>
                
                </div>
                </GridItem>
            

            <GridItem xs={12} sm={3} md={3}>
            <div className="appBox" style={{padding:"20px",background:" #FFFFFF",boxShadow: "5px 10px 30px -10px rgba(0, 0, 0, 0.2)",borderRadius: "25px"}}>
                <img style={{marginLeft:"4.8rem"}} src={require("../../assets/img/packing.png").default} alt="Truck Sewa"/>
                <h3 style={{textAlign:"center",fontSize:"18px",fontWeight:"700", color:"#343F52",lineHeight:"21px"}}>Safe Packing loading</h3>
                <p style={{marginBottom:"20px",textAlign:"center",fontSize:"12px",fontWeight:"300", color:"#60697B",lineHeight:"16.9px"}}>Consec tetur adipiscing elit ipsum dolor sit amet, consec tetur amet</p>
                <div style={{width:"100%", textAlign:"center"}}>
                  <a href={url} style={{padding: "8px 25px", borderRadius:" 20px", border: "solid thin #E98237",
                    fontSize: "14px",fontWeight: "500",color: "#E98237"}}>Read More</a>
                </div>
                
                </div>
                </GridItem>
            

            <GridItem xs={12} sm={3} md={3}>
            <div className="appBox" style={{padding:"20px",background:" #FFFFFF",boxShadow: "5px 10px 30px -10px rgba(0, 0, 0, 0.2)",borderRadius: "25px"}}>
                <img style={{marginLeft:"4.8rem",fontSize:"20px",fontWeight:"700"}} src={require("../../assets/img/track.png").default} alt="Truck Sewa"/>
                <h3 style={{textAlign:"center",fontSize:"18px",fontWeight:"700", color:"#343F52",lineHeight:"21px"}}>Track vehicles / driver</h3>
                <p style={{marginBottom:"20px",textAlign:"center",fontSize:"12px",fontWeight:"300", color:"#60697B",lineHeight:"16.9px"}}>Consec tetur adipiscing elit ipsum dolor sit amet, consec tetur amet</p>
                  <div style={{width:"100%", textAlign:"center"}}>
                <a href={url} style={{padding: "8px 25px", borderRadius:" 20px", border: "solid thin #E98237",
                    fontSize: "14px",fontWeight: "500",color: "#E98237"}}>Read More</a>
                </div>
                
                </div>
                </GridItem>
            

            <GridItem xs={12} sm={3} md={3}>
            <div className="appBox" style={{padding:"20px",background:" #FFFFFF",boxShadow: "5px 10px 30px -10px rgba(0, 0, 0, 0.2)",borderRadius: "25px"}}>
                <img style={{marginLeft:"4.8rem"}} src={require("../../assets/img/load-unload.png").default} alt="Truck Sewa"/>
                <h3 style={{textAlign:"center",fontSize:"18px",fontWeight:"700", color:"#343F52",lineHeight:"21px"}}>Safe Packing  loading</h3>
                <p style={{marginBottom:"20px",fontSize:"14px",textAlign:"center",fontWeight:"300", color:"#60697B",lineHeight:"16.9px"}}>Consec tetur adipiscing elit ipsum dolor sit amet, consec tetur amet</p>
                  <div style={{width:"100%", textAlign:"center"}}>
                <a href={url} style={{padding: "8px 25px", borderRadius:" 20px", border: "solid thin #E98237",
                    fontSize: "14px",fontWeight: "500",color: "#E98237"}}>Read More</a>
                </div>
                
                </div>
                </GridItem>
            

        </GridContainer>

      </div>
    </div>
  );
}


